import styled from "styled-components";


export const PortfolioContainer = styled.div`
   height: 500px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 120px;

  @media screen and (max-width: 768px) {
      height: auto;
      margin-bottom: 120px;
   }

   @media screen and (max-width: 480px) {
     height: auto;
     margin-bottom: 120;
  }
`;


export const PortfolioH1 = styled.h1`
font-size: 48px;
color: #fff;
margin-bottom: 54px;
border-bottom: 2px solid #977d4b; 
padding: 0px;
text-transform: uppercase; 

@media screen and (max-width: 480px) {
   font-size: 24px;
}

`;
export const PortfolioWrapper = styled.div`
 max-width: 1080px;
 margin: 0 auto;
 display: grid;
 grid-template-columns: 1fr 1fr 1fr;
 align-items: center;
 grid-gap: 16px;
 padding: 0 50px;

 @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
 };

 @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
 };
`;


export const PortfolioCard = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: center;
 border-radius: 10px;
 position: relative;
 padding: 0px;
 box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
 transition: all 0.2s ease-in-out;
 overflow: hidden;


 &:hover {
    transform: scale(1.02);
    transition: all 0.2s ease-in-out;
    cursor: pointer; 
 }
`;

export const PortfolioOverlay = styled.div`
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   opacity: 0;
   background: linear-gradient(0deg, rgba(3, 75, 108, 0.6), rgba(35, 212, 168, 0.3));

  &:hover {
   opacity: 1;
  }


`;
export const PortfolioImg = styled.img`
height: auto;
max-width: 100%;
-webkit-border-radius: 0;
border-radius: 10px;
-webkit-box-shadow: none;
box-shadow: none;
`;
export const PortfolioH2 = styled.h2`
  font-size: 22px;
  width: 100%;
  text-align: center;
  top: 50%;
  left: 0;
  position: absolute;
  color: #fff;


/* @media screen and (max-width: 1000px) {
 margin: 75px auto;
 };

 @media screen and (max-width: 768px) {
 margin: 75px auto;
 }; */
`;

