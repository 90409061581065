import  React from "react";
import "../App.css";
import CompleteWeddingPlanning from "../images/complete-wedding-planning-main.jpeg"
import { Button } from "../components/ButtonElements";
import { InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, TopLine, Heading, SubTitle, WeddingSectionPoints,  WeddingSectionPointsDesc,  BtnWrap, Column2, ImgWrap, Img} from "../components/Services/WeddingCoordinationElement";
import {Helmet} from "react-helmet";

 export default function WeddingCoordination() {
    return (
     <InfoContainer>
           <Helmet>
            <title>Complete Wedding Planning Delhi | Full Design &amp; Planning | Staunch Weddingz</title>
            <meta name="description" content="Your wedding planner will accompany you from the start of your trip until you are sipping a glass of bubbly the next day." />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <link rel="canonical" href="#" />
            <meta property="og:locale" content="en_IN" />
	        <meta property="og:type" content="article" />
            <meta property="og:title" content="Complete Wedding Planning Delhi | Full Design &amp; Planning | Staunch Weddingz" />
            <meta property="og:description" content="Your wedding planner will accompany you from the start of your trip until you are sipping a glass of bubbly the next day." />
            <meta property="og:url" content="#" />
	        <meta property="og:site_name" content="Staunch Weddingz" />
          <meta property="og:image" content="%PUBLIC_URL%/staunchlogo192.png" />
            <meta name="twitter:card" content="summary_large_image" />
          	<meta name="twitter:site" content="@staunch_weddingz" />
            </Helmet>
       <InfoWrapper>
           <InfoRow>
            <Column1 className="ServicesWeddingCoordination">
                          <TextWrapper>
              <TopLine>Services</TopLine>
              <Heading>Complete Wedding Planning</Heading>
              <SubTitle>We’ll be your trusted confidants from the get-go, drawing out the aspects that are special to you, designing and planning your wedding from the ground up so that it’s totally unique to you. 
                  This service is, quite frankly, the best way to guarantee both a stress-free planning experience and a wedding that surpasses all expectations.Revel in the possibilities, not the practicalities. And we’ll delight in the challenge of how to make your ideas happen.
              </SubTitle>
              <SubTitle>
              You’ll be updated at every stage, with regular meetings, notes, and support. Just some of the details you won’t have to worry about: apportioning your budget, managing schedules, creating and delivering a design concept, sourcing the perfect suppliers, making sure everything flows effortlessly on the day itself.
              </SubTitle>
              <WeddingSectionPoints>
              Investment
              </WeddingSectionPoints>
              <WeddingSectionPointsDesc>
              15% of your overall wedding budget, tiered minimum fees apply
              </WeddingSectionPointsDesc>
              <BtnWrap>
              <Button 
              primary="true"
              dark="true"
              smooth={true}
              duration={500} 
              spy={true} 
              exact={true} 
              offset={-80}
              to="/contact-us" 
              >Get In Touch</Button>
              </BtnWrap>
              </TextWrapper>
            </Column1>
            <Column2>
                <ImgWrap>
                <Img src={CompleteWeddingPlanning}/>
                </ImgWrap>
            </Column2>
           </InfoRow>
       </InfoWrapper>
      </InfoContainer>
    )
};
