import React, {useState, useEffect} from 'react';
import {Nav, NavbarContainer, NavLogo, MobileIcon, NavMenu, NavItem, NavLink, NavBtn, NavBtnLink} from "./NavbarElements";
import {FaBars} from "react-icons/fa";
import WebsiteLogo from "../../images/website-logo.png";
import {IconContext} from "react-icons/lib";
import {animateScroll as scroll} from "react-scroll";


const Navbar = ({ toggle }) => {

  const [scrollNav, setScrollNav] = useState(false);
   
  const changeNav = () => {
    if(window.scrollY >= 80) {
      setScrollNav(true)
    } else {
      setScrollNav(false)
    }
  };


  useEffect(() => {
    window.addEventListener('scroll', changeNav)
  }, []);


  const toggleHome = () => {
    scroll.scrollToTop();
  };

    return (
       <>
       <IconContext.Provider value={{color:'#fff'}}>
        <Nav scrollNav={scrollNav}>
         <NavbarContainer>
            <NavLogo to="/" onClick={toggleHome}>
               <img src={WebsiteLogo} alt="staunch weddingz" title="staunch weddingz" />
            </NavLogo>
            <MobileIcon onClick={toggle}>
                <FaBars />
            </MobileIcon>
            <NavMenu>
               <NavItem>
                <NavLink to="/" onClick={toggleHome} >Home</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/about-us"
                  smooth="true"
                  duration="500"
                  spy={true}
                  exact="true"
                  offset={-140}
                  >About Us</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/services"
                   smooth="true"
                   duration="500"
                   spy={true}
                   exact="true"
                   offset={-140}
                   >Services</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/portfolio"
                   smooth="true"
                   duration="500"
                   spy={true}
                   exact="true"
                   offset={-140}
                  >Portfolio</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/blog"
                   smooth="true"
                   duration="500"
                   spy={true}
                   exact="true"
                   offset={-140}
                  >Blog</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/testimonials"
                   smooth="true"
                   duration="500"
                   spy={true}
                   exact="true"
                   offset={-140}
                  >Testimonials</NavLink>
                </NavItem>
                <NavItem>
                  <NavLink to="/faqs" 
                   smooth="true"
                   activeClassName="active"
                   duration="500"
                   spy={true}
                   exact="true"
                   offset={-140}
                  >FAQ</NavLink>
                </NavItem>
            </NavMenu>
            <NavBtn>
                <NavBtnLink to="/contact-us"
                >Contact Us</NavBtnLink>
            </NavBtn>
         </NavbarContainer>
        </Nav>
        </IconContext.Provider>
       </>
    )
}

export default Navbar;
