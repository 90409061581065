import styled from "styled-components";
import {Link as FooterR} from "react-router-dom";


export const Container = styled.div`
padding: 80px 60px 10px;
background: radial-gradient(circle, rgba(35, 212, 168) 0%, rgba(3, 75, 108) 100%);`;

export const Wrapper = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
max-width: 1100px;
margin: 0 auto;
`;

export const SocialLink = styled.a`
   color: #fff;
   margin-bottom: 20px;
   font-size: 18px;
   text-decoration: none;

   &:hover {
       color: #034b6c;
       transition: 200ms ease-in;
   }

   @media screen and (max-width: 768px) {
    word-wrap: break-word;
 }
 @media screen and (max-width: 1000px) {
    word-wrap: break-word;
 }
`;


export const Column = styled.div`
display: flex;
flex-direction: column;
text-align: left;
margin-left: 60px;


@media screen and (max-width: 768px) {
    margin: 0;
}
`;

export const Row = styled.div`
display: grid;
grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
grid-gap: 20px;


@media (max-width: 1000px) {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
}

`;

export const Link = styled(FooterR)`
   color: #fff;
   margin-bottom: 20px;
   font-size: 18px;
   text-decoration: none;

   &:hover {
       color: #034b6c;
       transition: 200ms ease-in;
   }

   @media screen and (max-width: 768px) {
    word-wrap: break-word;
 }
 @media screen and (max-width: 1000px) {
    word-wrap: break-word;
 }
`;
export const Title = styled.p`
 font-size: 20px;
 color: #fff;
 margin-bottom: 40px;
 font-weight: bold;
`;


export const CopyRightArea = styled.div`

display: flex;
justify-content: space-between;
align-items: center;
max-width: 980px;
margin: 40px auto 0 auto;

@media screen and (max-width: 820px) {
    flex-direction: column;
};

@media screen and (max-width: 728px) {
    flex-direction: column;
}
@media screen and (max-width: 1000px) {
    flex-direction: column;
}
`;

export const SocialLogo = styled(Link)`
color: #fff;
justify-self: start;

img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
}
`;
export const WebsiteRights = styled.small`
color: #fff;
`;