export const Data = [
    {
        question: "WHERE ARE YOU BASED?",
        answer: "We’re based in Delhi, and plan out weddings across the globe.",
    },
    {
        question: "DO YOU PLAN DESTINATION WEDDINGS?",
        answer: "Yes, we have specialist in our team to plan destination marriages.",
    },
    {
        question: "WHAT STYLE OF WEDDING DO YOU PLAN?",
        answer: "Our job is to understand, draw upon and interpret your style. That said, the thing that our clients usually have in common is a love for natural elegance, understated luxury, distinctive details, and an easy, relaxed atmosphere.",
    },
    {
        question: "WHAT ABOUT MARQUEES?",
        answer: "Marquee weddings hold a very special place in our hearts and the increased number of logistical considerations this outdoor setting creates is a challenge we relish. That’s a big yes to marquee weddings from us!.",
    },
    {
        question: "DO YOU HAVE PREFERRED SUPPLIERS THAT YOU WORK WITH? AND DO WE HAVE TO USE THOSE SUPPLIERS?",
        answer: "We work with some of the best, most brilliantly talented suppliers in the industry. This means we’re uniquely placed to recommend the people we think are going to bring your ideas to life with passion and dedication. But recommendations are all they ever are. It’s your wedding, and we’re here to facilitate your decisions. We also love working with new suppliers, especially those that have a similar style and ethos to our own.",
    },
    {
        question: "HOW MANY WEDDINGS DO YOU WORK ON AT ANY ONE TIME?",
        answer: "We devote a huge amount of time and energy to you, so we book a very limited number of full plan weddings each year. It’s therefore a good idea to get in touch as soon as possible, especially if you dream of a winter wedding.",
    },
    {
        question: "ARE YOU FREE TO MEET UP OR TALK IN THE EVENING OR AT WEEKENDS?",
        answer: "Yes, we keep our schedules flexible to fit in around your commitments. The only exception is if we have a wedding that weekend as it’s all hands on deck!.",
    },
    {
        question: "ARE THERE ANY ADDITIONAL FEES WE SHOULD KNOW ABOUT? ",
        answer: "You’ll be provided with a bespoke quote following our initial consultation and kept updated at every stage regarding budgets. We don’t add commission for any referrals and transparency about costs is incredibly important to us.",
    },
    {
        question: "DO YOU CHARGE EXTRA FOR TRAVEL AND ACCOMMODATION?",
        answer: "Yes and no! Ultimately this depends on where you’re based, where you’re getting married, and how intensive the set-up and pack-down process is. If travel and accommodation costs are applicable, then we’ll always make you aware of this BEFORE you commit to proceeding with us.",
    },
    {
        question: "CAN YOU FIX YOUR FEE RATHER THAN CHARGING A PERCENTAGE?",
        answer: "Absolutely and if this is a route you’d prefer to take then please do let us know.",
    },
    {
        question: "WHAT ARE YOUR PAYMENT TERMS?",
        answer: "Payment terms are subject to how long we’ll be working together and which service you choose.For full plan weddings, there’s an initial 40% deposit to secure the date and our services. A 2nd payment is then due part the way through the planning process, with the remaining balance payable 4 weeks prior to your wedding.For Wedding Day Management, then payments are split on a 50% deposit and 50% balance basis.",
    },
    {
        question: "WHAT HAPPENS IF I CAN’T FIND THE SERVICE I’M LOOKING FOR?",
        answer: "Get in touch and we can talk about what you’re looking for. Our services can be completely tailored to you and your wedding so there’s definitely a lot of flexibility to ensure that our services work for you.",
    },
    {
        question: "DO YOU PLAN PARTIES OR EVENTS?",
        answer: "Yes, both! We love nothing more than an amazing party and the skills needed to plan a wedding also happen to create seriously special celebrations.",
    },

];