import styled from "styled-components";


export const BlogContainer = styled.div`
   height: auto;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 120px;

  @media screen and (max-width: 768px) {
      height: auto;
      margin-bottom: 120px;
   }

   @media screen and (max-width: 480px) {
     height: auto;
     margin-bottom: 120;
  }
`;

export const BlogHeading = styled.h1`
font-size: 48px;
color: #fff;
margin-bottom: 54px;
border-bottom: 2px solid #977d4b; 
padding: 0px;
text-transform: uppercase;  

@media screen and (max-width: 480px) {
   font-size: 24px;
}
`

export const BlogPostWrapper = styled.div`
 max-width: 1080px;
 margin: 0 auto;
 display: grid;
 grid-template-columns: 1fr 1fr;
 align-items: center;
 grid-gap: 16px;
 padding: 0 50px;

 @media screen and (max-width: 1000px) {
    grid-template-columns: 1fr 1fr;
 };

 @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
 };
`

export const BlogPostInfo = styled.div`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
text-align: center;
`;

export const BlogPostImg = styled.div`
 overflow: hidden;
animation-name: fade-img;
animation-duration: 2s;
border-radius: 10px;
box-shadow: 0px 3px 0px 0px #977d4b;

img {
height: 280px;
width: 100%;
object-fit: cover;
-webkit-border-radius: 0px;
border-radius: 10px;            
-webkit-box-shadow: none;
box-shadow: none;
display: block;
transition: all 0.2s linear;

&:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
    cursor: pointer; 
}
}
`;

export const BlogPostCats = styled.div`
margin-top: 10px;
`;

export const PostCat = styled.span`
font-size: 14px;
color: #fff;
line-height: 20px;
margin-top: 15px;
margin-right: 10px;
`;


export const BlogPostDate = styled.span`
    font-size: 14px;
    color: #fff;
    margin-top: 15px;
    background: #23d4a8;
    padding: 5px;
    border-radius: 4px;
`;

export const BlogPostTitle = styled.h1`
  font-size: 24px;
  color: #fff;
  line-height: 1.2;
  font-weight: 700;
  margin-top: 15px;
  font-style: italic;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  text-transform: capitalize;
  -webkit-box-orient: vertical;
`;
