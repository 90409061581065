import React from 'react'
import useForm from "./useForm";
import validate  from "./validateInfo";
import "./Form.css";

const FormContactUs = ({submitForm}) => {
   
const {handleChange, values, handleSubmit, errors} = useForm(submitForm, validate);
   
    return (
      <div className="form-content-right">
      <form classNmae="form" onSubmit={handleSubmit}>
       <h1 className="contactformh1">GET IN TOUCH</h1>
       <p className="contactformp">Fill out this quick contact form and we will get back in touch with you shortly.</p>
       <div className="form-inputs">
         <label 
         htmlFor="first_name" 
         className="form-label">
          First Name
         </label>
         <input 
              id="first_name"
              type="text"
              name="first_name"
              className="form-input"
              placeholder="Enter Your First Name" 
              value={values.first_name}
              onChange={handleChange}
             />
        {errors.first_name && <p>{errors.first_name}</p>}
       </div>
       <div className="form-inputs">
         <label 
           htmlFor="last_name"
           className="form-label">
            Last Name
         </label>
         <input 
             id="last_name"
             type="text" 
             name="last_name" 
             className="form-input"
             placeholder="Enter Your Last Name"
             value={values.last_name}
             onChange={handleChange}
             />
            {errors.last_name && <p>{errors.last_name}</p>}
       </div>
       <div className="form-inputs">
         <label 
            htmlFor="date_of_event"
            className="form-label">
             Date Of Event
         </label>
         <input
               id="date_of_event"
               type="date"
               value="2018-07-22"
               name="date_of_event"   
               className="form-input"
               value={values.date_of_event}
               onChange={handleChange}
             />
          {errors.date_of_event && <p>{errors.date_of_event}</p>}
       </div>
       <div className="form-inputs">
         <label 
          htmlFor="phonenumber"
           className="form-label">
           Phone Number
         </label>
         <input 
             id="contact_number"
             type="number" 
             name="contact_number"   
             className="form-input"
             placeholder="Enter Your Mobile Number"
             value={values.contact_number}
             onChange={handleChange}
             />
          {errors.contact_number && <p>{errors.contact_number}</p>}
       </div>
       <div className="form-inputs">
         <label 
          htmlFor="email_id"
           className="form-label">
            EMail Id
         </label>
         <input 
             id="reply_to"
             type="email" 
             name="reply_to" 
             className="form-input"
             placeholder="Enter your email"
             value={values.reply_to}
             onChange={handleChange}
             />
         {errors.reply_to && <p>{errors.reply_to}</p>}
       </div>
       <div className="form-inputs">
         <label 
          htmlFor="locationofevent"
           className="form-label">
            Location oF the Event
         </label>
         <input 
             id="location_of_event"
             type="text" 
             name="location_of_event" 
             className="form-input"
             placeholder="Enter Your Location oF The Event"
             value={values.location_of_event}
             onChange={handleChange}
             />
           {errors.location_of_event && <p>{errors.location_of_event}</p>}
       </div>
       <div className="form-inputs">
         <label 
          htmlFor="number_of_guest"
           className="form-label">
            Approx, Number of Guest
         </label>
         <input 
             id="number_of_guest"
             type="number" 
             name="number_of_guest"   
             className="form-input"
             placeholder="Enter Approx Number oF Guest"
             value={values.number_of_guest}
             onChange={handleChange}
            />
          {errors.number_of_guest && <p>{errors.number_of_guest}</p>}
       </div>
       <div className="form-inputs">
         <label 
          htmlFor="budget"
           className="form-label">
            Approx, Budget
         </label>
         <input 
             id="approx_budget"
             type="number" 
             name="approx_budget"   
             className="form-input"
             placeholder="Enter Approx Budget"
             value={values.approx_budget}
             onChange={handleChange}
             />
          {errors.approx_budget && <p>{errors.approx_budget}</p>}
       </div>
       <div className="form-inputs">
         <label 
          htmlFor="event_details"
           className="form-label">
            Event Details
         </label>
         <textarea className="form-textarea"
             id="event_details" 
             name="event_details"
             rows="5"
             cols="20"
            //  placeholder="Enter Event Details"
             value={values.event_details}
             onChange={handleChange}
             >
         </textarea>
         {errors.event_details && <p>{errors.event_details}</p>}
       </div>
       <div className="form-inputs">
       <button 
        className="form-input-btn" 
        type="submit">
        Submit
     </button>
       </div>
     </form>
      </div>
    )
}

export default FormContactUs;