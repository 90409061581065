export default function validateInfo (values) {
let errors = {}
// first name validation
if(!values.first_name.trim()) {
    errors.first_name = "First Name required"
} else if (!/^[A-Za-z]+$/.test(values.first_name)) {
    errors.first_name = "First Name is invalid"
}

//last name validation
if(!values.last_name.trim()) {
    errors.last_name = "Lastname required"
} else if (!/^[A-Za-z]+$/.test(values.last_name)) {
    errors.last_name = "Last Name is invalid"
}

//event date validation
if(!values.date_of_event.trim()) {
    errors.date_of_event = "Date of Event required"
}

//mobile number validation
if(!values.contact_number.trim()) {
    errors.contact_number = "Mobile Number required"
} else if (!/^[0-9a-zA-Z]+$/.test(values.contact_number)) {
    errors.contact_number = "Mobile Number is invalid"
}

//emailid validation
if(!values.reply_to.trim()) {
    errors.reply_to = "Email Id required"
} else if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(values.reply_to)) {
    errors.reply_to = "Email Address is invalid"
}

//event location validation
if(!values.location_of_event.trim()) {
    errors.location_of_event = "Location of Event required"
} else if (!/^[0-9a-zA-Z]+$/.test(values.location_of_event)) {
    errors.location_of_event = "Location of Event is invalid"
}

// number of guest validation
if(!values.number_of_guest.trim()) {
    errors.number_of_guest = "Approx Number of Guest required"
} else if (!/^[0-9]+$/.test(values.number_of_guest)) {
    errors.number_of_guest = "Number of Guest is invalid"
}

// number of approx_budget validation
if(!values.approx_budget.trim()) {
    errors.approx_budget = "Approx Budget required"
} else if (!/^[0-9]+$/.test(values.approx_budget)) {
    errors.approx_budget = "Approx budget is invalid"
}

// number of event_details validation
if(!values.event_details.trim()) {
    errors.event_details = "Event Details required"
}

return errors;

}