import React, {useState} from 'react';
import {Data} from "./Data";
import {IconContext} from "react-icons";
import {FiPlus, FiMinus} from "react-icons/fi";
import {AccordionSection, Container, Wrap, FaqDropdown, AccordionH1 } from "./AccordionElements";


const Accordion = () => {
   const [clicked, setClicked] = useState(false)

   const toggle = index =>{
       if(clicked === index) {
           // if user clicked question is already active, then close it
           return setClicked(null)
       }

       setClicked(index)
   }

    return (
    <IconContext.Provider value={{ color: "#fff", size: "25px"}}>
        <AccordionSection id="faq">
        <AccordionH1>
           <h1>FAQ's</h1>
            </AccordionH1>
            <Container>
                {Data.map((item, index) =>{
                    return(
                        <>
                        <Wrap onClick={() => toggle(index)} key={index}>
                        <h1>{item.question}</h1>
                        <span>{clicked === index ? <FiMinus /> : <FiPlus />}</span>
                        </Wrap>
                        {clicked === index ? (
                        <FaqDropdown>
                        <p>{item.answer}</p>
                        </FaqDropdown>
                          ) : null}
                        </>
                    )
                })}
            </Container>
        </AccordionSection>
    </IconContext.Provider>
    )
}

export default Accordion;
