import styled from "styled-components";
// import { Link } from "react-scroll";
import {Link as LinkR} from "react-router-dom";


export const Button = styled(LinkR)`
border-radius: 50px;
background: ${({ primary }) => (primary ? '#23d4a8' : '#034b6c')};
white-space: nowrap;
padding: ${({ big }) => (big ? '14px 48px' : '12px 30px')};
color: ${({ dark }) => (dark ? '#010606' : '#fff')};
font-size: ${({ fontBig }) => (fontBig ? '20px' : '16px')};
outline: none;
border: none;
text-transform: uppercase;
font-weight: 500;
display: flex;
text-decoration: none;
justify-content: center;
cursor: pointer;
align-items: center;
transition: all 0.2s ease-in-out;

&:hover {
    transition: all 0.2s ease-in-out;
    background: ${({primary}) => (primary ? '#121212' : '#23d4a8')};
    color: ${({dark}) => (dark ? '#fff' : '#010606')};
    font-weight: 500;
}
`;

