//herosection informativesection function section
export const homeObjInformativeSection = {
    id: 'informativesection',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    headline: 'International Luxury Wedding Planner',
    description: 'Bespoke wedding planning for modern, fun-loving couples, designed to match your personality and your style.',
    paragraph: 'At Staunch weddingz we plan, design, and produce exceptional celebrations. From small intimate gatherings to large and lavish affairs, our weddings and parties are memorable, magical experiences, perfectly planned and beautifully executed.',
    buttonLabel: 'Find Out More',
    dark: true,
    primary: true,
    darkText: false
};




