import React from 'react';
import { Button } from "../ButtonElements";
import AboutIllustration from "../../images/about-us-illustration-one.jpg";
import AboutIllustrationSecond from "../../images/about-us-illustration-second.jpg";
import { InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, TopLine, Heading, SubTitle, BtnWrap, Column2, ImgWrap, Img, Aboutillustrationimg, AboutusIllustrationInner, SquareCenter} from "./InfoElements";
const AboutInfoSection = ({ lightBg, id, imgStart, topLine, lightText, headline, darkText, description, buttonLabel, img, alt,
primary, dark, headlineOurStyles, topLineOurStyles, descriptionOurStyles, descriptionOurStylesSecond ,buttonLabelOurStyles, imgStartOurStyles, imgOurStyles, altOurStyles,
imgStartOurApproach, topLineOurApproach, headlineOurApproach, descriptionOurApproach, descriptionOurApproachSecond, buttonLabelOurApproachSecond, imgOurApproach, altOurApproach
}) => {

   return (
      <>
      <InfoContainer id={id} lightBg={lightBg} >
       <InfoWrapper>
           <InfoRow imgStart={imgStart}>
            <Column1>
            <TextWrapper>
                <TopLine>{topLine}</TopLine>
                <Heading  lightText={lightText}>{headline}</Heading>
                <SubTitle darkText={darkText}>{description}</SubTitle>
                <BtnWrap>
                <Button to="/contact-us" 
                 smooth={true}
                 duration={500} 
                 spy={true} 
                 exact={true} 
                 offset={-80}
                 primary={primary ? 1 : 0}
                 dark={dark ? 1 : 0}
                >{buttonLabel}</Button>
               </BtnWrap>
            </TextWrapper>
            </Column1>
            <Column2>
                <ImgWrap>
                <Img src={img} alt={alt}/>
                </ImgWrap>
            </Column2>
           </InfoRow>
           </InfoWrapper>
      </InfoContainer>
      <InfoContainer >
       <Aboutillustrationimg>
           <AboutusIllustrationInner>
            <SquareCenter></SquareCenter>
           </AboutusIllustrationInner>
             <img src={AboutIllustration} alt="about us illustrations"/>
            </Aboutillustrationimg>
       </InfoContainer>
      <InfoContainer id={id} lightBg={lightBg}>
      <InfoWrapper>
           <InfoRow imgStart={imgStartOurStyles}>
            <Column1>
            <TextWrapper>
                <TopLine>{topLineOurStyles}</TopLine>
                <Heading style={{fontStyle: "italic"}} lightText={lightText}>{headlineOurStyles}</Heading>
                <SubTitle darkText={darkText}>{descriptionOurStyles}</SubTitle>
                <SubTitle darkText={darkText}>{descriptionOurStylesSecond}</SubTitle>
                 <BtnWrap>
                <Button to="/portfolio" 
                 smooth={true}
                 duration={500} 
                 spy={true} 
                 exact={true} 
                 offset={-80}
                 primary={primary ? 1 : 0}
                 dark={dark ? 1 : 0}
                >{buttonLabelOurStyles}</Button>
               </BtnWrap>
            </TextWrapper>
            </Column1>
            <Column2>
                <ImgWrap>
                <Img src={imgOurStyles} alt={altOurStyles}/>
                </ImgWrap>
            </Column2>
           </InfoRow>
           </InfoWrapper>
      </InfoContainer>
      <InfoContainer id={id} lightBg={lightBg} style={{marginBottom: "70px"}}>
      <InfoWrapper>
      <InfoRow imgStart={imgStartOurApproach}>
            <Column1>
            <TextWrapper>
                <TopLine>{topLineOurApproach}</TopLine>
                <Heading style={{fontStyle: "italic"}} lightText={lightText}>{headlineOurApproach}</Heading>
                <SubTitle darkText={darkText}>{descriptionOurApproach}</SubTitle>
                <SubTitle darkText={darkText}>{descriptionOurApproachSecond}</SubTitle>
                 <BtnWrap >
                 <Button to="/services" 
                 smooth={true}
                 duration={500} 
                 spy={true} 
                 exact={true} 
                 offset={-80}
                 primary={primary ? 1 : 0}
                 dark={dark ? 1 : 0}
                >{buttonLabelOurApproachSecond}</Button>
               </BtnWrap>
            </TextWrapper>
            </Column1>
            <Column2>
                <ImgWrap>
                <Img src={imgOurApproach} alt={altOurApproach}/>
                </ImgWrap>
            </Column2>
           </InfoRow>
          </InfoWrapper>
          </InfoContainer>
          <InfoContainer>
       <Aboutillustrationimg>
           <AboutusIllustrationInner>
            <SquareCenter></SquareCenter>
           </AboutusIllustrationInner>
             <img src={AboutIllustrationSecond} alt="about us illustrations"/>
            </Aboutillustrationimg>
       </InfoContainer>
      </>
    )
};

export default AboutInfoSection;
