import React from 'react';
import {PortfolioContainer, PortfolioH1, PortfolioWrapper, PortfolioCard, PortfolioImg, PortfolioH2, PortfolioOverlay} from "./PortfolioElements";
import PortfolioImageTwo from "../../images/portfolio-image-two.jpg";
import PortfolioImageThree from "../../images/portfolio-image-three.jpg";
import PortfolioImageFour from "../../images/portfolio-image-four.jpg";
import PortfolioImageFive from "../../images/portfolio-image-five.jpg";
import PortfolioImageSix from "../../images/portfolio-image-six.jpg";
import PortfolioImageEight from "../../images/portfolio-image-eight.jpg";


const Portfolio = () => {
    return (
        <PortfolioContainer id="portfolio">
         <PortfolioH1>
             Our Portfolio
         </PortfolioH1>
         <PortfolioWrapper>
             <PortfolioCard>
                 <PortfolioImg src={PortfolioImageTwo} />
                 <PortfolioOverlay>
                 <PortfolioH2>Complete Weddding Planning</PortfolioH2>
                 </PortfolioOverlay>
          </PortfolioCard>
            <PortfolioCard>
                 <PortfolioImg src={PortfolioImageThree} />
                 <PortfolioOverlay>
                 <PortfolioH2>Wedding Coordination</PortfolioH2>
                 </PortfolioOverlay>    
             </PortfolioCard>
             <PortfolioCard>
                 <PortfolioImg src={PortfolioImageFour} />
                 <PortfolioOverlay>
                 <PortfolioH2>Complete Weddding Planning</PortfolioH2>
                 </PortfolioOverlay>   
             </PortfolioCard>
             <PortfolioCard>
                 <PortfolioImg src={PortfolioImageFive} />
                 <PortfolioOverlay>
                 <PortfolioH2>Wedding Coordination</PortfolioH2>
                 </PortfolioOverlay>   
             </PortfolioCard>
             <PortfolioCard>
                 <PortfolioImg src={PortfolioImageSix} />
                 <PortfolioOverlay>
                 <PortfolioH2>Complete Weddding Planning</PortfolioH2>
                 </PortfolioOverlay>   
             </PortfolioCard>
             <PortfolioCard>
                 <PortfolioImg src={PortfolioImageEight} />
                 <PortfolioOverlay>
                 <PortfolioH2>Wedding Coordination</PortfolioH2>
                 </PortfolioOverlay>   
             </PortfolioCard>
         </PortfolioWrapper>
        </PortfolioContainer>
    )
};

export default Portfolio;

