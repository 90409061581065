import React from 'react';
import SuccessImage from "../../images/form-success.svg"

const FormSuccess = () => {
    return (
        <div className="form-content-right">
        <div className="form-success">
          We have Received your request!
        </div>
          <img src={SuccessImage} alt="success-image" className="form-img-2" />
        </div>
    )
}

export default FormSuccess;
