import React from 'react';
import HeroSection from '../components/HeroSection';
import CarouselMain from '../components/HeroSection/CarouselMain';
import {Helmet} from "react-helmet";
import { homeObjInformativeSection} from '../components/HeroSection/Data';

function Home() {

    return (
        <>
         <Helmet>
            <title>Wedding and Event Planners | Delhi - Staunch Weddingz</title>
            <meta name="description" content="Wedding and Event Planners at Staunch Weddingz Events organise elegant and understated events in Delhi, India." />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <link rel="canonical" href="#" />
            <meta property="og:locale" content="en_IN" />
	          <meta property="og:type" content="article" />
            <meta property="og:title" content="Wedding and Event Planners | Delhi - Staunch Weddingz" />
            <meta property="og:description" content="Wedding and Event Planners at Staunch Weddingz Events organise elegant and understated events in Delhi, India." />
            <meta property="og:url" content="#" />
	          <meta property="og:site_name" content="Staunch Weddingz" />
            <meta property="og:image" content="%PUBLIC_URL%/staunchlogo192.png" />
            <meta name="twitter:card" content="summary_large_image" />
          	<meta name="twitter:site" content="@staunch_weddingz" />
            </Helmet>
         <CarouselMain />
         <HeroSection {...homeObjInformativeSection} />
         
        </>
    )
}

export default Home
