import React from 'react';
import {BlogContainer,BlogHeading, BlogPostWrapper, BlogPostImg, BlogPostInfo, BlogPostCats, PostCat, BlogPostDate, BlogPostTitle} from "./BlogElements"


const blogdata = [
    {
     id: 1,
     imgsrc: require('../../images/blog-image-one.jpg').default,
     blogcategoryone: "Wedding Coordination",
     blogcategorytwo: "Complete Wedding Planning",
     blogpostdate: "20 May, 2021",
     blogposttitle: "4 Unique Ways to Personalise Your Wedding Ceremony",
    },

    {
        id: 2,
        imgsrc: require('../../images/blog-image-two.jpg').default,
        blogcategoryone: "Wedding Coordination",
        blogcategorytwo: "Complete Wedding Planning",
        blogpostdate: "17 May, 2021",
        blogposttitle: "The Amazing Way to Personalise Your Wedding",
    },

    {
        id: 3,
        imgsrc: require('../../images/blog-image-three.jpg').default,
        blogcategoryone: "Wedding Coordination",
        blogcategorytwo: "Complete Wedding Planning",
        blogpostdate: "12 May, 2021",
        blogposttitle: "Wedding Flower trends 2022 predictions",
    },
    {
        id: 4,
        imgsrc: require('../../images/blog-image-four.jpg').default,
        blogcategoryone: "Wedding Coordination",
        blogcategorytwo: "Complete Wedding Planning",
        blogpostdate: "08 May, 2021",
        blogposttitle: "COVID-19 Latest Wedding Update",
    },
     
    ];

function BlogCard() {
    return (
        <BlogContainer id="blog">
                 <BlogHeading>
                    Blog
                 </BlogHeading>
                 <BlogPostWrapper>
                 {blogdata.map(blogpost => ( 
                <BlogPostInfo key={blogpost.id} >
                    <BlogPostImg>
                   <img src={blogpost.imgsrc} alt=""/>
                   </BlogPostImg>
                    <BlogPostCats>
                   <PostCat>{blogpost.blogcategoryone}</PostCat>
                   <PostCat>{blogpost.blogcategorytwo}</PostCat>
                   </BlogPostCats>
                   <BlogPostDate>
                   {blogpost.blogpostdate}
                 </BlogPostDate>
                 <BlogPostTitle>
                  {blogpost.blogposttitle}
                 </BlogPostTitle>
                </BlogPostInfo> ))}
                </BlogPostWrapper>
                </BlogContainer>
    )

};

export default BlogCard;