import  React from "react";
import "../App.css";
import BlogCard from "../components/Blog/BlogCard";
import {DefaultContainerMain, CarouselContainer } from "./styles/SinglePageElements";
import CarouselMain from '../components/HeroSection/CarouselMain';
import {Helmet} from "react-helmet";

class BlogMain extends React.Component {
  render() {
      return (
       <DefaultContainerMain>
          <Helmet>
            <title>Wedding Planning Ideas | Blog | Staunch Weddingz</title>
            <meta name="description" content="Wedding Planning Ideas Blog - Staunch Weddingz -  Blogs - Wedding Offers. Leaders in the event planning industry. Delhi, India." />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <link rel="canonical" href="#" />
            <meta property="og:locale" content="en_IN" />
	          <meta property="og:type" content="article" />
            <meta property="og:title" content="Wedding Planning Ideas | Blog | Staunch Weddingz" />
            <meta property="og:description" content="Wedding Planning Ideas Blog - Staunch Weddingz -  Blogs - Wedding Offers. Leaders in the event planning industry. Delhi, India." />
            <meta property="og:url" content="#" />
	          <meta property="og:site_name" content="Staunch Weddingz" />
            <meta property="og:image" content="%PUBLIC_URL%/staunchlogo192.png" />
            <meta name="twitter:card" content="summary_large_image" />
          	<meta name="twitter:site" content="@staunch_weddingz" />
            </Helmet>
            <CarouselContainer>
          <CarouselMain />
          </CarouselContainer>
        <BlogCard />
       </DefaultContainerMain>
      )
  }
};

export default BlogMain;