import styled from "styled-components";


//style for infocontainer
export const InfoContainer = styled.div`
min-height: 100%;
justify-content: center;
align-items: center;
position: relative;
z-index: 1;
color: #fff;
background: ${({lightBg}) => (lightBg ? '#23d4a8' : '#034b6c')};

@media screen and  (max-width: 768px) {
    padding: 50px 0;
}

@media screen and  (max-width: 468px) {
    padding: 50px 0;
}

@media screen and  (max-width: 1000px) {
    padding: 50px 0;
}
`;

// style for infowrapper
 export const InfoWrapper = styled.div`
 display: grid;
 z-index: 1;
 width: 100%;
 max-width: 1100px;
 height: 700px;
 margin-left: auto;
 margin-right: auto;
 padding:0 44px;
 justify-content: center;

 @media screen and (max-width: 768px) {
    height: auto;
 }
 @media screen and (max-width: 468px) {
    height: auto;
 }

 @media screen and  (max-width: 1000px) {
    height: auto;
}

`;

//style for inforow
export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col1 col2'`)};


 @media screen and (max-width: 768px) {
     grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}; 
 }
`;

//style for column1 
export const Column1 = styled.div`
margin-bottom:  15px;
padding: 0 15px;
grid-area: col1;
`;

//style for column2 
export const Column2 = styled.div`
margin-bottom:  15px;
padding: 0 15px;
grid-area: col2;
`;


//style for TextWrapper
export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 20px;
`;

//style for TextWrapper
export const TopLine = styled.p`
color: #23d4a8;
font-size: 16px;
line-height: 16px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;
`;

// style for heading
export const Heading = styled.h1`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
font-weight: 600;
color: ${({ lightText }) => (lightText ? '#fff' : '#23d4a8')};

@media  screen and  (max-width: 480px) {
    font-size: 32px;
}
`;

//style for subtitle
export const SubTitle = styled.p`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({darkText}) => (darkText ? '#23d4a8' : '#fff')};
`;


//style for btnwrap
export const BtnWrap = styled.div`
display: flex;
justify-content: flex-start;
`;

// style fot imgwrap
export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
`;

// style for img
export const Img = styled.img`
width: 100%;
margin: 0 0  10px 0;
padding-right: 0;
`;

// style for aboutusillustrationing
export const Aboutillustrationimg = styled.div`
    display: inline-block;
    position: relative;
    width: 100%;


img {
    position: relative;
    max-width: 100%;
    width: 100%;
    
}
`;

// style for AboutusIllustrationInner
export const AboutusIllustrationInner = styled.div`
    position: absolute;
    top: -30px;
    width: 100%;
    overflow-x: hidden;
    z-index: 10;

    @media screen and (max-width: 768px) {
        top: -20px;
    }
    @media screen and (max-width: 1000px) {
        top: -20px;
    }

    @media screen and (max-width: 468px) {
        top: -20px;
    }
`;

// style for AboutusIllustrationInner
export const SquareCenter = styled.div`
    border: 2px solid white;
    position: relative;
    float: none;
    text-align: center;
    margin: 0 auto;
    width: 650px;
    height: 650px;

@media screen and (max-width: 768px) {
    width: 223px;
    height: 223px;
}
@media screen and (max-width: 1000px) {
    width: 223px;
    height: 223px;
}
@media screen and (max-width: 468px) {
    width: 223px;
    height: 223px;
}
`;


