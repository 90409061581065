import React, {useState} from 'react';
import { HeroContainer, HeroBg, ImageBg, HeroContent, HeroBtnWrapper, HeroH1, HeroP, HeroDesc, ArrowForward, ArrowRight} from "./HeroElements";
import {Button} from "../ButtonElements";

const HeroSection = ({ lightBg, id, headline, description, paragraph, buttonLabel,
  primary, dark
  }) => {

    const [hover, setHover] = useState(false)

    const onHover = () => {
        setHover(!hover)
    };


    return (
          <HeroContainer id={id} lightBg={lightBg}>
              <HeroBg>
               <ImageBg />
              </HeroBg>
            <HeroContent>
            <HeroH1>{headline}</HeroH1>
            <HeroP>{description}</HeroP>
            <HeroDesc>{paragraph}</HeroDesc>
            <HeroBtnWrapper>
                <Button to="/services" onMouseEnter={onHover} onMouseLeave={onHover}
                 smooth={true}
                 duration={500} 
                 spy={true} 
                 exact={true} 
                 offset={-80}
                 primary={primary ? 1 : 0}
                 dark={dark ? 1 : 0}
                > {buttonLabel} {hover ? <ArrowForward /> : <ArrowRight />}
                </Button>
            </HeroBtnWrapper>
            </HeroContent>
          </HeroContainer>
          
    )
}

export default HeroSection;
