//about us function section
export const homeObjAbout = {
    id: 'about-us',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLine: 'Staunch Weddingz',
    headline: 'LUXURY WEDDING PLANNERS',
    description: 'Staunch Weddingz are the international luxury wedding planners of choice for those seeking authentic and unique celebrations. We have built a global reputation for creating outstanding luxury weddings. From small intimate gatherings to large and lavish affairs, we offer first class service and plan world class events. Passionate about what we do, we strive to ensure all our celebrations are exceptional. Across the world we work with the best in the business, and together, set trends rather than follow them.',
    buttonLabel: 'Get Started',
    imgStart: true,
    img: require('../../images/svg-1.svg').default,
    alt: 'About',
    dark: true,
    primary: true,
    darkText: false
};


  //about us our style  function section
 export const  HomeObjAboutOurStyles = {
    id: 'ourstyles',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLineOurStyles: 'Our',
    headlineOurStyles: 'Style',
    descriptionOurStyles: 'We share our clients passion for effortless, understated elegance, delectably abundant blooms, delectable food and wine, and the joy of creating unforgettable occasions and experiences. We are in planning heaven when we add in a stunning and quintessentially English setting.',
    descriptionOurStylesSecond: 'Our days are split between Delhi, India, which is charming and feeds our obsession with Farrow & Ball paint and classic English style.',
    buttonLabelOurStyles: 'Portfolio',
    imgStartOurStyles: true,
    imgOurStyles: require('../../images/about-us-our-styles.svg').default,
    altOurStyles: 'Our Styles',
    dark: true,
    primary: true,
    darkText: false
};

  //about us our Approach function section
  export const  HomeObjAboutOurApproach = {
    id: 'ourapproach',
    lightBg: false,
    lightText: true,
    lightTextDesc: true,
    topLineOurApproach: 'Our',
    headlineOurApproach: 'Approach',
    descriptionOurApproach: 'We listen to you and care about you as your wedding planners. Throughout the process, we keep you informed. We are generous with our time, meet on a regular basis, and introduce you to like-minded vendors we know you will enjoy as much as we do. We will probably become really good friends as well, because getting to know each other socially is, in our opinion, an equally important part of planning your wedding.',
    descriptionOurApproachSecond: 'And, while we will collaborate closely in the months leading up to your wedding, we would not be able to do what we do without the creative, exceptionally talented vendors who back us up and help us realise your vision. On any given day, you will find us collaborating as a cohesive team, one step ahead of every detail. We are your personal team of experienced, talented experts, all dedicated to planning and executing a one-of-a-kind wedding for you.',
    buttonLabelOurApproachFirst: 'Want to Talk',
    buttonLabelOurApproachSecond: 'Our Services',
    imgStartOurApproach: false,
    imgOurApproach: require('../../images/about-us-our-approach.svg').default,
    altOurApproach: 'Our Styles',
    dark: true,
    primary: true,
    darkText: false
};







