import React from 'react';
import { SidebarContainer, Icon, CloseIcon, SidebarWrapper, SidebarMenu, SidebarLink, SidebarBtnWrap, SidebarRoute} from "./SidebarElements";


const Sidebar = ({ isOpen, toggle }) => {
    return (
   <SidebarContainer isOpen={isOpen} onClick={toggle}>
       <Icon onClick={toggle}>
           <CloseIcon />
       </Icon>
       <SidebarWrapper>
           <SidebarMenu>
               {/* // this is react scrolling not router */}
               <SidebarLink to="home" onClick={toggle}>
                 Home
               </SidebarLink>
               <SidebarLink to="about-us" onClick={toggle}>
                   About us
               </SidebarLink>
               <SidebarLink to="services" onClick={toggle}>
                  Services
               </SidebarLink>
               <SidebarLink to="/portfolio" onClick={toggle}>
                  Portfolio
               </SidebarLink>
               <SidebarLink to="/blog" onClick={toggle}>
                   Blog
               </SidebarLink>
               <SidebarLink to="/testimonials" onClick={toggle}>
                   Testimonials
               </SidebarLink>
               <SidebarLink to="/faqs" onClick={toggle}>
                   FAQs
               </SidebarLink>
           </SidebarMenu>
           <SidebarBtnWrap>
               <SidebarRoute to="/contact-us" onClick={toggle}> Contact Us </SidebarRoute>
               {/* <SidebarRoute to="/sign-in"> Sign In </SidebarRoute> */}
           </SidebarBtnWrap>

       </SidebarWrapper>
   </SidebarContainer>
    )
}

export default Sidebar;
