import styled from "styled-components";


//style for servicescontainer
export const ServicesContainer = styled.div`
min-height: 100vh;
background: #034b6c;
color: #fff;
justify-content: center;
align-items: center;
padding: 0 30px;
position: relative;
z-index: 1;
margin-bottom: 50px;
`;

//style for serviceHeading
export const ServicesHeading = styled.div`
z-index: 3;
padding: 8px 20px;
display: flex;
flex-direction: column;
align-items: center;
`;

//style for service
export const ServicesH1 = styled.h1`
color: #fff;
font-size: 48px;
text-align: center;
border-bottom: 2px solid #977d4b; 
padding: 0px;
text-transform: uppercase; 

@media screen and (max-width: 768px) {
    font-size: 40px;
}

@media screen and (max-width: 480px) {
    font-size: 30px;
}
`;
export const ServicesP = styled.p`
   color: #fff;
   margin-top: 24px;
   font-size: 22px;
   text-align: center;
   max-width: 960px;

 
@media screen and (max-width: 768px) {
    font-size: 24px;
}

@media screen and (max-width: 480px) {
    font-size: 18px;
}
`;




