import  React from "react";
import "../App.css";
import AboutInfoSection from "../components/InfoSection/AboutUsInfo";
import { homeObjAbout, HomeObjAboutOurApproach, HomeObjAboutOurStyles} from '../components/InfoSection/Data';
import {DefaultContainerMain, AboutUsMainHeading, CarouselContainer } from "./styles/SinglePageElements";
import {Helmet} from "react-helmet";
import CarouselMain from '../components/HeroSection/CarouselMain';


class AboutUs extends React.Component {
  render() {
      return (
       <DefaultContainerMain>
             <Helmet>
            <title>Luxury Wedding Planner | AboutUs - Staunch Weddingz &amp; Events</title>
            <meta name="description" content="Luxury wedding planner, Staunch Weddingz is passionate, experienced and immersive. He creates sensational, exclusive and distinctive weddings and events." />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <link rel="canonical" href="#" />
            <meta property="og:locale" content="en_IN" />
	          <meta property="og:type" content="article" />
            <meta property="og:title" content="Wedding Planner | AboutUs - Staunch Weddingz &amp; Events" />
            <meta property="og:description" content="Luxury wedding planner, Staunch Weddingz is passionate, experienced and immersive. He creates sensational, exclusive and distinctive weddings and events." />
            <meta property="og:url" content="#" />
	          <meta property="og:site_name" content="Staunch Weddingz" />
            <meta property="og:image" content="%PUBLIC_URL%/staunchlogo192.png" />
            <meta name="twitter:card" content="summary_large_image" />
          	<meta name="twitter:site" content="@staunch_weddingz" />
            </Helmet>
          <CarouselContainer>
          <CarouselMain />
          </CarouselContainer>
        <AboutUsMainHeading>
         <h1>About Us</h1>
        </AboutUsMainHeading>
        <AboutInfoSection {...homeObjAbout} {...HomeObjAboutOurStyles} {...HomeObjAboutOurApproach} />
       </DefaultContainerMain>
      )
  }
};

export default AboutUs;