import  React from "react";
import "../App.css";
import Form from "../components/ContactUs/Form"
import {DefaultContainerMain, ContactUsMainHeading, CarouselContainer } from "./styles/SinglePageElements";
import CarouselMain from '../components/HeroSection/CarouselMain';
import {Helmet} from "react-helmet";


class ContactUs extends React.Component {
  render() {
      return (
       <DefaultContainerMain>
             <Helmet>
            <title>Staunch Weddingz | Wedding &amp; Event Planners Delhi | Contact Us</title>
            <meta name="description" content="Staunch Weddingz Events | Wedding &amp; Event Planners Delhi | Get in touch with us today | xxxxxxxxx | info@staunchweddingz.com" />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <link rel="canonical" href="#" />
            <meta property="og:locale" content="en_IN" />
	          <meta property="og:type" content="article" />
            <meta property="og:title" content="Staunch Weddingz| Wedding &amp; Event Planners Delhi | Contact Us" />
            <meta property="og:description" content="Staunch Weddingz Events | Wedding &amp; Event Planners Delhi | Get in touch with us today | xxxxxxxxx | info@staunchweddingz.com" />
            <meta property="og:url" content="#" />
	          <meta property="og:site_name" content="Staunch Weddingz" />
            <meta property="og:image" content="%PUBLIC_URL%/staunchlogo192.png" />
            <meta name="twitter:card" content="summary_large_image" />
          	<meta name="twitter:site" content="@staunch_weddingz" />
            </Helmet>
	       <CarouselContainer>
          <CarouselMain />
         </CarouselContainer>
        <ContactUsMainHeading>
         <h1>Contact Us</h1>
        </ContactUsMainHeading>
         <Form />
       </DefaultContainerMain>
      )
  }
};

export default ContactUs;
