import  React from "react";
import "../App.css";
import Portfolio from "../components/Portfolio/Portfolio"
import {DefaultContainerMain, CarouselContainer} from "./styles/SinglePageElements";
import CarouselMain from '../components/HeroSection/CarouselMain';
import {Helmet} from "react-helmet";
class PortfolioLanding extends React.Component {
  render() {
      return (
       <DefaultContainerMain>
          <Helmet>
            <title>Weddings Planning | Portfolio - Staunch Weddingz</title>
            <meta name="description" content="Weddings Decoration, Inspiring images from weddings and events across Delhi, and the India. Images from Delhi Wedding Planner - Staunch Weddingz" />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <link rel="canonical" href="#" />
            <meta property="og:locale" content="en_IN" />
	          <meta property="og:type" content="article" />
            <meta property="og:title" content="Weddings Planning | Portfolio - Staunch Weddingz" />
            <meta property="og:description" content="Weddings Decorations, Inspiring images from weddings and events across Delhi, and the India. Images from Delhi Wedding Planner - Staunch Weddingz" />
            <meta property="og:url" content="#" />
	          <meta property="og:site_name" content="Staunch Weddingz" />
            <meta property="og:image" content="%PUBLIC_URL%/staunchlogo192.png" />
            <meta name="twitter:card" content="summary_large_image" />
          	<meta name="twitter:site" content="@staunch_weddingz" />
            </Helmet>
            <CarouselContainer>
          <CarouselMain />
         </CarouselContainer>
        <Portfolio />
       </DefaultContainerMain>
      )
  }
};

export default PortfolioLanding;