import CardItem from './CardItem';
import "./Cards.css";
import {ServicesHeading , ServicesContainer, ServicesH1, ServicesP} from "./ServicesElements";
import WeddingCardImagesOne from '../../images/complete-wedding-planning.jpg';
import WeddingCardImagesTwo from '../../images/on-the-day-wedding.jpg';

function Cards() {
    return (
     <ServicesContainer id="services">
      <ServicesHeading>
        <ServicesH1>Services</ServicesH1>
        <ServicesP>The main focus of our company is our bespoke luxury wedding planning, design and management service. We assist our clients in creating memorable, magical celebrations that exceed expectations. Our personal approach ensures the weddings we plan are meaningful and truly reflect our clients as individuals, as couples, and states something about their shared values and sense of style. Working exclusively in the luxury events sector, we have an international network of exceptional event suppliers who meet quantifiable standards of service and delivery. Wherever in the world we work, we undertake to never compromise on either. And we know that outstanding design is only half the story of your celebration; great weddings are all about people and we always work with you and the guest experience in mind.</ServicesP>
      </ServicesHeading>         
      <div className="cards"> 
        <div className="cards__container">
         <div className="cards__wrapper">
             <ul className="cards__items">
                 <CardItem 
                  src={WeddingCardImagesOne}
                  text="Complete Wedding Planning"
                  description="Also called full planning. It starts right at the beginning, before you’ve planned anything, other than maybe your venue. It ends as you head off on your honeymoon…"
                  label="Wedding"
                  path="/services/complete-wedding-planning"
                 />
                  <CardItem 
                  src={WeddingCardImagesTwo}
                  text="Wedding Coordination"
                  description="Also called on-the-day coordination. It starts roughly 2 weeks to a month before your wedding, when you’ve planned every detail yourself. It ends with the last dance of the evening…"
                  label="Wedding"
                  path="/services/wedding-coordination"
                 />
             </ul>
         </div>
        </div>
      </div>
      </ServicesContainer>
    )
};

export default Cards;
