import React, {useState} from 'react';
import FormContactUs from "./FormContactUs";
import FormSuccess from "./FormSuccess";
import ContactUsImage from "../../images/contact-us.svg";

const Form = () => {

  const [isSubmitted, setIsSubmitted] = useState(false);
  function submitForm() {
    setIsSubmitted(true);
  }
    return (
         <>
         <div className="form-container" id="contactus">
          <div className="form-content-left">
           <img src={ContactUsImage} alt="contact-us" className="form-img" />
          </div>
          {!isSubmitted ? (<FormContactUs submitForm={submitForm} /> )  : (<FormSuccess />)}
         </div>
      
        </>
    )
}

export default Form;
