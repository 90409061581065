import styled from "styled-components";


//style for infocontainer
export const InfoContainer = styled.div`
color: #fff;
padding: 100px;
background: ${({lightBg}) => (lightBg ? '#23d4a8' : '#034b6c')};

@media screen and  (max-width: 768px) {
    padding: 100px 0;
}
`;

// style for infowrapper
 export const InfoWrapper = styled.div`
 display: grid;
 z-index: 1;
 width: 100%;
 max-width: 1100px;
 height: 100%;
 margin-left: auto;
 margin-right: auto;
 padding:0 24px;
 justify-content: center;
`;

//style for inforow
export const InfoRow = styled.div`
display: grid;
grid-auto-columns: minmax(auto, 1fr);
align-items: center;
grid-template-areas: ${({imgStart}) => (imgStart ? `'col2 col1'` : `'col2 col1'`)};


 @media screen and (max-width: 768px) {
     grid-template-areas: ${({imgStart}) => (imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`)}; 
 }
`;

//style for column1 
export const Column1 = styled.div`
margin-bottom:  15px;
padding: 0 15px;
grid-area: col1;
border: 2px solid #A77D4B;
`;

//style for column2 
export const Column2 = styled.div`
margin-bottom:  15px;
padding: 0 15px;
grid-area: col2;
`;


//style for TextWrapper
export const TextWrapper = styled.div`
max-width: 540px;
padding-top: 0;
padding-bottom: 20px;
`;

//style for Topline
export const TopLine = styled.p`
color: #23d4a8;
font-size: 16px;
line-height: 16px;
font-weight: 700;
letter-spacing: 1.4px;
text-transform: uppercase;
margin-bottom: 16px;
margin-top: 10px;
`;

// style for heading
export const Heading = styled.h1`
margin-bottom: 24px;
font-size: 48px;
line-height: 1.1;
font-weight: 600;
color: ${({ lightText }) => (lightText ? '#fff' : '#23d4a8')};

@media  screen and  (max-width: 480px) {
    font-size: 32px;
}
`;

//style for subtitle
export const SubTitle = styled.p`
max-width: 440px;
margin-bottom: 35px;
font-size: 18px;
line-height: 24px;
color: ${({darkText}) => (darkText ? '#23d4a8' : '#fff')};
`;

//style for weddingsectionpoint
export const WeddingSectionPoints = styled.h3`
max-width: 440px;
margin-bottom: 10px;
text-align: center;
font-size: 18px;
line-height: 24px;
color: ${({darkText}) => (darkText ? '#23d4a8' : '#fff')};
`;

//style for weddingsectionpointdesc
export const WeddingSectionPointsDesc = styled.p`
max-width: 440px;
margin-bottom: 35px;
text-align: center;
font-size: 18px;
line-height: 24px;
color: ${({darkText}) => (darkText ? '#23d4a8' : '#fff')};
`;

//style for btnwrap
export const BtnWrap = styled.div`
display: flex;
justify-content: center;
`;

// style fot imgwrap
export const ImgWrap = styled.div`
max-width: 555px;
height: 100%;
`;

// style for img
export const Img = styled.img`
width: 100%;
margin: 0 0  10px 0;
padding-right: 0;
display: flex;
box-shadow: 0 6px 20px rgba(56, 125, 255, 0.17);
-webkit-filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
filter: drop-shadow(0 6px 20px rgba(56, 125, 255, 0.017));
border-radius: 10px;
overflow: hidden;
`;

