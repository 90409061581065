import styled from "styled-components";


export const TestimonialsContainer = styled.div`
   height: 400px;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   text-align: center;
   margin-bottom: 60px;

 @media screen and (max-width: 768px) {
      height: auto;
      margin: 0;
   }
  @media screen and (max-width: 480px) {
     height: auto;
     margin: 0;
  }
`;

export const TestimonialsH1 = styled.h1`
font-size: 48px;
color: #fff;
margin-bottom: 54px;
border-bottom: 2px solid #977d4b; 
padding: 0px;
text-transform: uppercase;  

@media screen and (max-width: 480px) {
   font-size: 24px;
};
`;

export const TestimonialsWrapper = styled.div`
 max-width: 1080px;
 margin: 0 auto;
 grid-template-columns: 1fr 1fr 1fr;
 align-items: center;
 grid-gap: 16px;
 padding: 0 50px;


 @media screen and (max-width: 1000px) {
  overflow: hidden;
  width: 100%;
  max-width: 100%;
  padding: 0 20px;
 };

 @media screen and (max-width: 768px) {
    width: 100%;
    max-width: 100%;
    max-width: 100%;
    padding: 0 20px;
 }
`;

export const SlideContent = styled.div`
 display: flex;
 flex-direction: column;
 justify-content: flex-start;
 align-items: center;
 border-radius: 10px;
 height: 180px;
 padding: 0px;
 transition: all 0.2s ease-in-out;


 @media screen and (max-width: 768px) {
  height: 400px;
  overflow: hidden;
 }

 @media screen and (max-width: 1000px) {
  height: 400px;
  overflow: hidden;

}
`;


export const TestimonialsH2 = styled.h2`
font-size: 22px;
text-align: center;
justify-content: center;
align-items: center;
margin-top: 10px;
margin-bottom:10px;
color: #fff;
`;


export const TestimonialsDesc= styled.p`
font-size: 16px;
text-align: center;
padding-left: 60px;
padding-right: 60px;
justify-content: center;
align-items: center;
color: #fff;


@media screen and (max-width:768px) {
   font-size: 14px;
}

@media screen and (max-width:1000px) {
   font-size: 14px;
}
`;