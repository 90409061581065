import styled from "styled-components";


export const DefaultContainerMain = styled.main`
margin: 0;
`;


export const CarouselContainer = styled.div`
 margin-bottom: 50px;
`;

export const ContactUsMainHeading = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   margin-bottom: 54px;

   h1 {
   font-size: 48px;
   color: #fff;
   border-bottom: 2px solid #977d4b; 
   padding: 0px;
   text-transform: uppercase; 
 
   @media screen and (max-width: 480px) {
   font-size: 24px;
}
}

`;

export const AboutUsMainHeading = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;

   h1 {
   font-size: 48px;
   color: #fff;
   border-bottom: 2px solid #977d4b; 
   padding: 0px;
   text-transform: uppercase; 
 
   @media screen and (max-width: 480px) {
   font-size: 24px;
}
}

`

