import styled from "styled-components";
import {MdKeyboardArrowRight, MdArrowForward} from "react-icons/md";

// style of herocontainer
export const HeroContainer = styled.div`
display: flex;
justify-content: center;
align-items: center;
padding: 0 30px;
height: 100vh;
position: relative;
z-index: 1;

 /* add before style */
 :before {
     content: '';
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 0;
     background-image: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
     linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
     z-index: 2;
 }
`

// style of herocontainer
export const HeroBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

// style of VideoBg
export const ImageBg = styled.img`
background-color: transparent;
background-image: linear-gradient(210deg to right, #034b6c 40%, rgba(8, 37, 72, 0) 23%);
-o-object-fit: cover;
object-fit: cover;
/* background: #034b6c; */
`;

// style of herocontent
export const HeroContent = styled.div`
z-index: 3;
max-width: 1200px;
position: absolute;
padding: 8px 20px;
display: flex;
flex-direction: column;
align-items: center;
`;

// style of heroh1
export const HeroH1 = styled.h1`
color: #fff;
font-size: 48px;
text-align: center;

@media screen and (max-width: 768px) {
    font-size: 40px;
}

@media screen and (max-width: 480px) {
    font-size: 30px;
}
`;

// style of herop
export const HeroP = styled.p`
   color: #fff;
   margin-top: 20px;
   font-size: 24px;
   font-style: italic;
   text-align: center;
   font-weight: 700;
   max-width: 600px;

 
@media screen and (max-width: 768px) {
    font-size: 24px;
}

@media screen and (max-width: 480px) {
    font-size: 18px;
}
`;

// style of herodesc
export const HeroDesc = styled.p`
   color: #fff;
   margin-top: 24px;
   font-size: 22px;
   text-align: center;
   max-width: 600px;

 
@media screen and (max-width: 768px) {
    font-size: 24px;
}

@media screen and (max-width: 480px) {
    font-size: 18px;
}
`;

//style of herobtnwrapper
export const HeroBtnWrapper = styled.div`
  margin-top: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// style of arrowforward
export const ArrowForward = styled(MdArrowForward)`
margin-left: 8px;
font-size: 20px;
`;

//style of arrowright
export const ArrowRight = styled(MdKeyboardArrowRight)`
margin-left: 8px;
font-size: 20px;
`