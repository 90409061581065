
import React from 'react';
import { Fragment } from 'react'; 
import ImageSlider from "./ImageSlider";

import image1 from "../../images/slider-banner-1.jpg";
import image2 from "../../images/slider-banner-2.jpg";
import image3 from "../../images/slider-banner-3.jpg";
import image4 from "../../images/slider-banner-4.jpg";
import image5 from "../../images/slider-banner-5.jpg";
import image6 from "../../images/slider-banner-6.jpg";

import ScrollBottom from "./ScrollBottom";

const CarouselMain = () => {
    return (
    <Fragment>
        <ScrollBottom /> 
    <ImageSlider images={[image1, image2, image3, image4, image5, image6]}>
   </ImageSlider>
    </Fragment>
    )
}

export default CarouselMain;



