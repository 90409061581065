import styled from "styled-components";

export const AccordionSection = styled.div`
display: flex;
flex-direction: column;
min-height: 100vh;
background: #034b6c;
color: #fff;
justify-content: center;
align-items: center;
width: 992px;
max-width: 100% ;
margin: 0 auto;
justify-content: center;
align-items: center;
margin-bottom: 100px;
position: relative;
z-index: 1;

@media screen and (max-width: 768px) {
    margin-top: 60px;
    padding: 10px;
}

@media screen and (max-width: 100px) {
    margin-top: 60px;
    padding: 10px;
}

@media screen and (max-width: 468px) {
    margin-top: 60px;
    padding: 10px;
}

`;

export const AccordionH1 = styled.div`
h1 {
color: #fff;
font-size: 48px;
text-align: center;
margin-bottom: 30px;
border-bottom: 2px solid #977d4b; 
padding: 0px;
text-transform: uppercase;

@media screen and (max-width: 768px) {
    margin-bottom: 30px;
    margin-top: 30px;
    font-size: 24px;
}
}
`

export const Container = styled.div`
position: relative;
top: 30%;
box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 7px 20px 0 rgba(0, 0, 0, 0.2);
`;

export const Wrap = styled.div`
background: #23d4a8;
color: #fff;
display: flex;
margin-bottom: 10px;
margin-top: 10px;
justify-content: space-between;
align-items: center;
width: 100%;
text-align: left;
cursor: pointer;

h1 {
    padding: 2rem;
    font-size: 20px;

@media screen and (max-width: 768px) {
     font-size: 18px;
     line-height: 1.4;
};
@media screen and (max-width: 1000px) {
     font-size: 18px;
     line-height: 1.4;
};
@media screen and (max-width: 468px) {
     font-size: 18px;
     line-height: 1.4;
};
}

span {
    margin-right: 1.5rem;
}
`;

export const FaqDropdown = styled.div`
background: #034d6a;
color: #fff;
margin-bottom: 10px;
width: 100%;
height: 100px;
display: flex;
flex-direction: column;
align-items: left;
border-bottom: 2px solid #A77D4B;
border-top: 2px solid #A77D4B;

@media screen and (max-width: 768px) {
     height: auto !important;
};

@media screen and (max-width: 1000px) {
     height: auto !important;
};

@media screen and (max-width: 468px) {
     height: auto !important;
};

p {
    font-size: 16px;
    padding: 10px;
}
`;