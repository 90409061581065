import React, {useState} from "react";
import './App.css';
import { BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Home from "./pages";
// import Services from "./pages/Services";
import WeddingCoordination from "./pages/WeddingCoordination";
import CompleteWeddingPlanning from "./pages/CompleteWeddingPlanning";
import Services from "./pages/Services";
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import ContactUs from './pages/ContactUs';
import { FooterContainer } from './containers/footer';
import ScrollToTop from "./components/ScrollToTop";
import AboutUs from "./pages/AboutUs";
import PortfolioLanding from "./pages/PortfolioLanding";
import BlogMain from "./pages/BlogMain";
import TestimonialsLanding from "./pages/TestimonialsLanding";
import FaqsLanding from "./pages/FaqsLanding";

const App = ()  => {

  const [isOpen , setIsOpen ] = useState(false)
  // true false state open and close state
  const toggle = () => {
      setIsOpen(!isOpen)
  }


  return (
   
     <Router>
       <ScrollToTop>
       <Sidebar isOpen={isOpen} toggle={toggle} />
        <Navbar toggle={toggle} />
       <Switch>
       <Route path='/' exact component={Home} />
       <Route path="/about-us/" exact component={AboutUs} />
       <Route path="/services/" exact component={Services} />
       <Route path="/portfolio/" exact component={PortfolioLanding} />
       <Route path="/blog/" exact component={BlogMain} />
       <Route path="/contact-us/" exact component={ContactUs} />
       <Route path="/testimonials/" exact component={TestimonialsLanding} />
       <Route path="/faqs/" exact component={FaqsLanding} />
       <Route path="/services/wedding-coordination/" exact component={WeddingCoordination} />
       <Route path="/services/complete-wedding-planning/" exact component={CompleteWeddingPlanning} />
      </Switch>
      <FooterContainer />
      </ScrollToTop>
    </Router>
  );
}

export default App;
