import styled from "styled-components";
import {Link as LinkR} from "react-router-dom";
// import {Link as LinkS } from "react-scroll";


// style of navbar main
export const Nav= styled.nav`
  /* background-image: linear-gradient(to left, #23d4a8 , #034b6c); */
  background: ${({ scrollNav }) => (scrollNav ? 'linear-gradient(to left, #23d4a8 , #034b6c);' : 'transparent')};
  height: 80px;
  margin-top: -80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  position: sticky;
  top: 0;
  z-index: 10;
 
  @media screen and (max-width: 960px) {
      transition: 0.8s all ease
  }
  
  
`;


// style navbar container
export const NavbarContainer = styled.div`
display: flex;
justify-content: space-between;
height: 80px;
z-index: 1;
width: 100%;
padding: 0 24px;
max-width: 1100px;
`;

// style navlogo
export const NavLogo = styled(LinkR)`
color: #fff;
justify-self: flex-start;
cursor: pointer;
font-size: 1.5rem;
display: flex;
align-items: center;
margin-left: 24px;
font-weight: 700;
text-decoration: none;

img {
    width: 80px;
    max-width: 100%;
    height: 80px;
    max-height: 100%;
    border-radius: 50px;
    padding: 2px;
}
`;

// style mobileicon
export const MobileIcon = styled.div`
display: none;

@media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0%;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #fff;
}
`;

// style navmenu
export const NavMenu = styled.ul`
display: flex;
align-items: center;
text-align: center;
list-style: none;
margin-right: -8px;

@media screen and (max-width: 768px) {
    display: none
}
`;

// style navitem
export const NavItem = styled.li`
height: 80px;
`;

//style NavLink
export const NavLink = styled(LinkR)`
color: #fff;
display: flex;
align-items: center;
height: 100%;
padding: 0 1rem;
cursor: pointer;
text-decoration: none;

&.active {
    border-bottom: 3px solid #A77D4B;
}

.active {
    border-bottom: 3px solid #A77D4B; 
}
`;

//style navbtn
export const NavBtn = styled.nav`
display: flex;
align-items: center;

@media screen and (max-width: 768px) {
    display: none;
}
`;

//style navbtnlink
export const NavBtnLink = styled(LinkR)`
   border-radius: 50px;
   background: #fff;
   white-space: nowrap;
   font-weight: 500;
   padding: 10px 22px;
   color: #010606;
   font-size: 16px;
   outline: none;
   margin-right: 10px;
   border: none;
   cursor: pointer;
   transition: all 0.2s ease-in-out;
   text-decoration: none;

   &:hover {
       transition: all 0.2s ease-in-out;
       background: #054b6b;
       color: #fff;
       font-weight: 500;
   }
`;

