import styled from "styled-components";
import {FaTimes} from "react-icons/fa";
import {Link as LinkR} from "react-router-dom";
// import {Link as LinkS } from "react-scroll";

//style of sidebarcontainer
export const SidebarContainer = styled.aside`
 position: fixed;
 z-index: 999;
 width: 100%;
 height: 100%;
 background: #1e768e;
 display: grid;
 align-items: center;
 top: 0;
 left: 0;
 transition: 0.3s ease-in-out;
 opacity: ${({ isOpen }) => ( isOpen ? '100%' : '0')};
 top: ${({ isOpen }) =>(isOpen ? '0' : '-100%')};
`;

//style of closeicon
export const CloseIcon = styled(FaTimes)`
color: #fff;
`;

//style of icon
export const Icon = styled.div`
position: absolute;
top: 1.2rem;
right: 1.5rem;
background: transparent;
font-size: 2rem;
outline: none;
cursor: pointer;
`;

//style of sidebarwrapper
export const SidebarWrapper = styled.div`
color: #fff;
`;

//style of sidebarmenu
export const SidebarMenu = styled.ul`
display: grid;
grid-template-columns: 1fr;
grid-template-rows: repeat(7, 60px);
text-align: center;

@media screen and (max-width: 480px) {
    grid-template-rows: repeat(7, 60px);
}
`;

//style of sidebarlink
export const SidebarLink = styled(LinkR)`
display: flex;
align-items: center;
justify-content: center;
color: #fff;
cursor: pointer;
font-size: 1.5rem;
text-decoration: none;
list-style: none;
transition: 0.2s ease-in-out;

&:hover {
    color: #23d4a8;
    transition: 0.2s ease-in-out;
}
`;

//style of sidebarbtnwrap
export const SidebarBtnWrap = styled.div`
display: flex;
margin-top: 20px;
justify-content: center;
align-items: center;
`;

//style of sidebarroute
export const SidebarRoute = styled(LinkR)`
background-color: #034b6c;
white-space: nowrap;
padding: 16px 64px;
color: #fff;
font-size: 16px;
outline: none;
border: none;
cursor: pointer;
transition: all 0.2s ease-in-out;
text-decoration: none;
border-radius: 50px;

&:hover {
    transition: all 0.2s ease-in-out;
    background: #034b6c;
    color: #fff;
}
`;


