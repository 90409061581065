import {useState, useEffect} from "react";
import emailjs from 'emailjs-com';

const useForm = (callback, validate) => {
    const [values, setValues] = useState({
        first_name: "",
        last_name: "",
        date_of_event: "",
        contact_number: "",
        reply_to: "",
        location_of_event: "",
        number_of_guest: "",
        approx_budget: "",
        event_details: "",
    });
    
    const [errors, setErrors] = useState({});
    const [send, setToSend] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    useEffect(() => {
        if (Object.keys(errors).length === 0  && isSubmitting) {
          if (isSubmitting) {
            setToSend(true);
          }
        }
      }, [errors]);
    
      const handleChange = (e)=> {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value,
          });
        setErrors(validate(values));
        setIsSubmitting(true);
    };

     const handleSubmit = (e) => {
         e.preventDefault();
         if (send) {
            console.log("Thankyou for Your Details");
            emailjs
            .sendForm(
            'service_1hs2tlh',
            'template_nf7iv6k',
            e.target,
            'user_YBwiSy8o0ADM27FiZiS4u'
          )
          .then((response) => {
            console.log('SUCCESS!', response.status, response.text);
          })
          .catch((err) => {
            console.log('FAILED...', err);
          });
        callback();
        e.target.reset();
      }
    };
 
    return { handleChange, values, handleSubmit, errors}
};

export default useForm;