import  React from "react";
import "../App.css";
import Cards from "../components/Services/Cards"
import {DefaultContainerMain,  CarouselContainer } from "./styles/SinglePageElements";
import {Helmet} from "react-helmet";
import CarouselMain from '../components/HeroSection/CarouselMain';

class Services extends React.Component {
  render() {
      return (
       <DefaultContainerMain>
          <Helmet>
            <title>Wedding Planner &amp; Event Planning Services Delhi &amp; India | Staunch Weddingz</title>
            <meta name="description" content="The ability to laugh, love, be inspired, and enjoy every moment of your celebration. Here you'll find versatile and completely customised planning services." />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <link rel="canonical" href="#" />
            <meta property="og:locale" content="en_IN" />
	          <meta property="og:type" content="article" />
            <meta property="og:title" content="Wedding Planner &amp; Event Planning Services Delhi &amp; India | Staunch Weddingz" />
            <meta property="og:description" content="The ability to laugh, love, be inspired, and enjoy every moment of your celebration. Here you'll find versatile and completely customised planning services." />
            <meta property="og:url" content="#" />
	          <meta property="og:site_name" content="Staunch Weddingz" />
            <meta property="og:image" content="%PUBLIC_URL%/staunchlogo192.png" />
            <meta name="twitter:card" content="summary_large_image" />
          	<meta name="twitter:site" content="@staunch_weddingz" />
            </Helmet>
            <CarouselContainer>
          <CarouselMain />
          </CarouselContainer>
        <Cards />
       </DefaultContainerMain>
      )
  }
};

export default Services;