import React, {useState} from 'react';
import {FaArrowCircleDown} from 'react-icons/fa';
import styled from 'styled-components';
	
export const ScrollBottomButton = styled.div`
position: fixed;
width: 100%;
left: 50%;
top: 90%;
height: 20px;
font-size: 2.4rem;
z-index: 1;
cursor: pointer;
color: white;

@media screen and (max-width: 768px) {
top: 30%;
left: 0;
right: 0;
text-align: center;
justify-content: center;
align-items: center;
}

@media screen and (max-width: 468px) {
top: 30%;
left: 0;
right: 0;
text-align: center;
justify-content: center;
align-items: center;
}

@media screen and (max-width: 1000px) {
top: 30%;
left: 0;
right: 0;
text-align: center;
justify-content: center;
align-items: center;
}
`;
	
const ScrollButton = () =>{
	
const [visible, setVisible] = useState(true)
	
const toggleVisible = () => {
	const scrolled = document.documentElement.scrollTop;
	if (scrolled > 0){
	setVisible(false)
	}
	else if (scrolled <= 0){
	setVisible(true)
	}
};
	
const scrollToBottom = () =>{
	window.scrollTo({
	top: document.documentElement.scrollHeight,
	behavior: 'smooth',
	/* you can also use 'auto' behaviour
		in place of 'smooth' */
	});
};
	
window.addEventListener('scroll', toggleVisible);
	
return (
	<ScrollBottomButton>
	<FaArrowCircleDown onClick={scrollToBottom}
	style={{display: visible ? 'inline' : 'none'}} />
	</ScrollBottomButton>
);
}
	
export default ScrollButton;
