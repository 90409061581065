import React from "react";
import Footer from "../components/footer";
import Icon from "../components/icons";
import FooterLogo from "../images/website-logo.png";
import {animateScroll as scroll} from "react-scroll";


export function FooterContainer() {
 const toggleHome = () => {
    scroll.scrollToTop();
  };


   return(
      <Footer>
          <Footer.Wrapper>
              <Footer.Row>
              <Footer.Column>
                  <Footer.Title>Staunch Weddingz</Footer.Title>
                  <Footer.Link to="/services/complete-wedding-planning">Complete Wedding Planning</Footer.Link>
                  <Footer.Link to="/services/wedding-coordination">Wedding Coordination</Footer.Link>
              </Footer.Column>
              <Footer.Column>
                  <Footer.Title>
                    Website Links
                  </Footer.Title>
                  <Footer.Link to="/about-us">About Us</Footer.Link>
                  <Footer.Link to="/services">Services</Footer.Link>
                  <Footer.Link to="/portfolio">Portfolio</Footer.Link>
                  <Footer.Link to="/blog">Blog</Footer.Link>
                  <Footer.Link to="/testimonials">Testimonials</Footer.Link>
                  <Footer.Link to="/faqs">Faqs</Footer.Link>
              </Footer.Column>
              <Footer.Column>
                  <Footer.Title>
                     Information
                  </Footer.Title>
                  <Footer.Link to="/contact-us">Contact Us</Footer.Link>
                  <Footer.Link href="#">Privacy Policy</Footer.Link>
                  <Footer.Link href="#">Cookie Policy</Footer.Link>
                  <Footer.Link  href="#">Terms and Conditions</Footer.Link>
              </Footer.Column>
              <Footer.Column>
                  <Footer.Title>
                    Get in Touch
                  </Footer.Title>
                  <Footer.SocialLink href="tel:+91 9818606462"><Icon className="fab fa fa-phone" />+91 9818606462</Footer.SocialLink>
                  <Footer.SocialLink  href="#"><Icon className="fab fa fa-map-marker" />Delhi, India</Footer.SocialLink>
                  <Footer.SocialLink href="https://www.facebook.com/staunchweddingz"><Icon className="fab fa fa-facebook" />Facebook</Footer.SocialLink>
                  <Footer.SocialLink href="https://in.pinterest.com/priyanka9067/"><Icon className="fab fa fa-pinterest" />Pinterest</Footer.SocialLink>
                  <Footer.SocialLink  href="https://instagram.com/staunchweddingz"><Icon className="fab fa fa-instagram" />Instragram</Footer.SocialLink>
                  <Footer.SocialLink  href="https://www.youtube.com/channel/UCHlYvkJ7z2U4EtQc9xB22LQ/videos"><Icon className="fab fa fa-youtube" />Youtube</Footer.SocialLink>
                  
                 </Footer.Column>
              </Footer.Row>
          </Footer.Wrapper>
          <Footer.CopyRightArea>
              <Footer.SocialLogo to="/" onClick={toggleHome}><img src={FooterLogo} alt="footerlogo" /></Footer.SocialLogo>
              <Footer.WebsiteRights>Staunch Weddingz © 2016 - {new Date().getFullYear()}</Footer.WebsiteRights>
              <Footer.WebsiteRights>Develop By Adarsh Tripathi</Footer.WebsiteRights>
          </Footer.CopyRightArea>
      </Footer>
   )
};

