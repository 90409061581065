import  React from "react";
import "../App.css";
import WeddingCoordinationImages from "../images/wedding-coordination.jpg"
import { Button } from "../components/ButtonElements";
import { InfoContainer, InfoWrapper, InfoRow, Column1, TextWrapper, TopLine, Heading, SubTitle, WeddingSectionPoints,  WeddingSectionPointsDesc,  BtnWrap, Column2, ImgWrap, Img} from "../components/Services/WeddingCoordinationElement";
import {Helmet} from "react-helmet";

 const WeddingCoordination =() => {
      return (
        <InfoContainer>
           <Helmet>
            <title>Day of wedding co-ordination | Staunch Weddingz</title>
            <meta name="description" content="Our day of wedding coordination starts a month before your wedding date, when you realise you'll need some assistance to ensure everything goes smoothly." />
            <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />
            <link rel="canonical" href="#" />
            <meta property="og:locale" content="en_IN" />
	          <meta property="og:type" content="article" />
            <meta property="og:title" content="Day of wedding co-ordination | Staunch Weddingz" />
            <meta property="og:description" content="Our day of wedding coordination starts a month before your wedding date, when you realise you'll need some assistance to ensure everything goes smoothly." />
            <meta property="og:url" content="#" />
	          <meta property="og:site_name" content="Staunch Weddingz" />
            <meta property="og:image" content="%PUBLIC_URL%/staunchlogo192.png" />
            <meta name="twitter:card" content="summary_large_image" />
          	<meta name="twitter:site" content="@staunch_weddingz" />
            </Helmet>
       <InfoWrapper>
           <InfoRow>
            <Column1 className="ServicesWeddingCoordination">
            <TextWrapper>
                <TopLine>Services</TopLine>
                <Heading>WeddingCoordination</Heading>
                <SubTitle>Even if you relish the opportunity to plan your own wedding, you’re probably not picturing yourself on the day itself with a clipboard and schedule in hand as you tell people where they need to park, unload, set-up etc.This is where our wedding day / on-the-day expert team comes in.</SubTitle>
                <SubTitle>
                Since 2015, We’ve coordinated countless weddings and truly is the best at making sure people, décor and details are where they need to be, discreetly ironing out anything that comes up, and giving you the freedom to enjoy the experience of your wedding from your guests’ perspective. If you choose this service our team will be your dedicated point of contact from the moment you handover your plans.
              </SubTitle>
              <SubTitle>
              Just some of the details you won’t have to worry about: overseeing the set-up of your venue, creating a detailed schedule and timeline, liaising with and coordinating suppliers on the day, smoothing over last-minute changes, ironing out any unforeseeable hiccups, assisting guests.
            </SubTitle>
               <WeddingSectionPoints>
               Investment
               </WeddingSectionPoints>
               <WeddingSectionPointsDesc>
               Rs 1,00,000.00 ONWARDS
              </WeddingSectionPointsDesc>
                <BtnWrap>
                <Button 
                 primary="true"
                 dark="true"
                 smooth={true}
                 duration={500} 
                 spy={true} 
                 exact={true} 
                 offset={-80}
                 to="/contact-us" 
                >Get In Touch</Button>
               </BtnWrap>
            </TextWrapper>
            </Column1>
            <Column2>
                <ImgWrap>
                <Img src={WeddingCoordinationImages}/>
                </ImgWrap>
            </Column2>
           </InfoRow>
       </InfoWrapper>
      </InfoContainer>
    )
};

export default  WeddingCoordination;
