import React from 'react'
import {TestimonialsContainer,TestimonialsWrapper, SlideContent, TestimonialsH1,TestimonialsH2, TestimonialsDesc} from "./TestimonialsElements";
import TestimonialsUserImage from "../../images/testimonials.png";
import "./Testimonials.css";
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore , {Navigation, Pagination, Scrollbar, A11y, Autoplay, EffectFade, Lazy, Mousewheel, Thumbs} from "swiper";
// Import Swiper styles
import 'swiper/swiper.scss';    
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, EffectFade, Autoplay, Lazy, Mousewheel, Thumbs]);

const data = [
    {
     id: 1,
     username: "Anonymous",
     testimonials: "I've had the pleasure of dealing with Staunch and his crew on a number of occasions, and I can't say enough good things about them. Staunch's attention to detail, sense for design, and relationships with both his clients and suppliers are what really set him apart. His events are usually visually gorgeous and extremely well-organized, and he will go above and beyond to ensure that your celebrations go off without a hitch.",
    },
    
    {
        id: 2,
        username: "Anonymous",
        testimonials: "From both me and Joe, a heartfelt thank you! Our entire day went off without a hitch. You and your colleagues did an amazing job coordinating our wedding day. We will be eternally grateful to you for making our wedding fantasies come true!.",
    },
    
    {
        id: 3,
        username: "Anonymous",
        testimonials: "Staunch, I just wanted to express my gratitude for being so wonderful and planning the wedding of our dreams for us yesterday. We had the finest day ever, and our visitors enjoyed themselves as well. You went above and beyond and made everyone feel at ease, which helped the day run smoothly. We can't express our gratitude enough for this incredible experience. We'll miss our weekly conversations with you!",
    },
    
    ];

  function Testimonials() {
    return (
    <TestimonialsContainer id="testimonials">
        <TestimonialsH1>
            Testimonials
        </TestimonialsH1>
     <TestimonialsWrapper>
      <Swiper
        effect="fade"
        lazy
        autoplay={{delay: 500,disableOnInteraction: false}}
        spaceBetween={20}
        slidesPerView={3}
        navigation
        mousewheel
        thumbs
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        onSlideChange={() => console.log('slide change')}
        onSwiper={(swiper) => console.log(swiper)}
      >
        {data.map(user => ( 
        <SwiperSlide key={user.id} className="swipermain">
            <SlideContent>
              <img src={TestimonialsUserImage} alt="testimonialslogo"/>
            <TestimonialsH2>
              {user.username}
              </TestimonialsH2>
              <TestimonialsDesc>
              {user.testimonials}
              </TestimonialsDesc>
          </SlideContent>
        </SwiperSlide> ))}
        ...
      </Swiper>
      </TestimonialsWrapper>
      </TestimonialsContainer>
    );
  };

  export default Testimonials;
